import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedInUser: {},
    userAccounts: [],
    activeToken: "",
    activeRefresh: false,
    isBusinessAccount: false,
    account: null,
    selectedCompany: null,
    isAdmin: false,
    isSuperAdmin: false,
    companyToView: null,
    companyToEdit: false,
    callReportCategory: "",
  },
  // Getters for All Permissions
  getters: {
    loggedInUser: state => state.loggedInUser,
    userAccounts: state => state.userAccounts,
    activeToken: state => state.activeToken,
    activeRefresh: state => state.activeRefresh,
    isBusinessAccount: state => state.isBusinessAccount,
    account: state => state.account,
    selectedCompany: state => state.selectedCompany,
    isAdmin: state => state.isAdmin,
    isSuperAdmin: state => state.isSuperAdmin,
    companyToView: state => state.companyToView,
    companyToEdit: state => state.companyToEdit,
    callReportCategory: state => state.callReportCategory,
  },
  mutations: {
    setLoggedInUser(state, user) {
      state.loggedInUser = user;
    },
    setUserAccounts(state, accounts) {
      state.userAccounts = accounts;
    },
    setToken(state, token) {
      state.activeToken = token;
    },
    setRefresh(state, refresh) {
      state.activeRefresh = refresh;
    },
    changeAccountType(state, bool) {
      state.isBusinessAccount = bool;
    },
    switchAccount(state, company_id) {
      state.account = company_id;
    },
    setSelectedCompany(state, company_data) {
      state.selectedCompany = company_data;
    },
    setAdminSuperAdmin(state, data){
      state.isAdmin = data.isAdmin;
      state.isSuperAdmin = data.isSuperAdmin
    },
    setCompanyToView(state, company_id, type) {
      state.companyToView = company_id;
      state.companyToEdit = false;
    },
    setCompanyToEdit(state) {
      state.companyToEdit = true;
    },
    setCallReportCategory(state, category) {
      state.callReportCategory = category;
    },
  },
});
