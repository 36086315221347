<template>
    <div id="digi-splash-screen">
        <div class="center">
            <div class="logo">
                <img width="168" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/dclogo-sat.png" alt="DigiCollect">
            </div>
            <div class="spinner-wrapper">
                <span style="color: #0d84f2">
                    Loading
                    <div class="wave">
                        <span class="dot"></span>
                        <span class="dot"></span>
                        <span class="dot"></span>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>
<style>
.one {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 99999;
    pointer-events: none;
}
.one .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.hollow-dots-spinner {
    text-align: center;
    margin: 0 auto;
}

#digi-splash-screen {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 99999;
    pointer-events: none;
}

#digi-splash-screen .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

#digi-splash-screen .logo {
    width: 168px;
    margin: 0 auto;
}

#digi-splash-screen .logo img {
    filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))
}

#digi-splash-screen .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px;
    text-align: center;
}

#digi-splash-screen .spinner-wrapper .spinner {
    position: absolute;
    overflow: hidden;
    left: 50%;
    margin-left: -50px;
    animation: outer-rotate 2.91667s linear infinite;
}

#digi-splash-screen .spinner-wrapper .spinner .inner {
    width: 100px;
    height: 100px;
    position: relative;
    animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#digi-splash-screen .spinner-wrapper .spinner .inner .gap {
    position: absolute;
    left: 49px;
    right: 49px;
    top: 0;
    bottom: 0;
    border-top: 10px solid;
    box-sizing: border-box;
}

#digi-splash-screen .spinner-wrapper .spinner .inner .left,
#digi-splash-screen .spinner-wrapper .spinner .inner .right {
    position: absolute;
    top: 0;
    height: 100px;
    width: 50px;
    overflow: hidden;
}

#digi-splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
#digi-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 10px solid #4285F4;
    border-bottom-color: transparent;
    border-radius: 50%;
}

#digi-splash-screen .spinner-wrapper .spinner .inner .left {
    left: 0;
}

#digi-splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
    left: 0;
    border-right-color: transparent;
    animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#digi-splash-screen .spinner-wrapper .spinner .inner .right {
    right: 0;
}

#digi-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
    right: 0;
    border-left-color: transparent;
    animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

@keyframes outer-rotate {
    0% {
        transform: rotate(0deg) scale(0.5);
    }
    100% {
        transform: rotate(360deg) scale(0.5);
    }
}

@keyframes left-wobble {
    0%, 100% {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
}

@keyframes right-wobble {
    0%, 100% {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
}

@keyframes sporadic-rotate {
    12.5% {
        transform: rotate(135deg);
    }
    25% {
        transform: rotate(270deg);
    }
    37.5% {
        transform: rotate(405deg);
    }
    50% {
        transform: rotate(540deg);
    }
    62.5% {
        transform: rotate(675deg);
    }
    75% {
        transform: rotate(810deg);
    }
    87.5% {
        transform: rotate(945deg);
    }
    100% {
        transform: rotate(1080deg);
    }
}



#digi-splash-screen .spinner-wrapper p {
    font-size: 24px;
    padding-top: 20px;
    color: #0d84f2;
    display: inline-block;
}
#digi-splash-screen .spinner-wrapper div.wave {
    display: inline-block;
    padding-left: 5px;
}
.wave .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    margin-right: 1px;
    background: #0d84f2;
    animation: wave 1.3s linear infinite;
}

.wave .dot:last-of-type{
    margin-right:0
}
.wave .dot:nth-child(2){
    animation-delay:-1.1s
}
.wave .dot:nth-child(3){
    animation-delay:-.9s
}
@keyframes wave{
    0%,100%,60%{
        transform:initial
    }
    30%{
        transform:translateY(-6px)
    }
}
</style>

<script>
//import { HollowDotsSpinner } from "epic-spinners";

export default {
  components: {
    //HollowDotsSpinner
  }
}
</script>
