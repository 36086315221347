import globals from "../globals.js";
import axios from 'axios';
export default {
    data() {
        return {
            warning_msg: "",
        }
    },
    methods:{

        // Companies apis
        async createCompany(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/company/", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        async checkCompDomainName(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/company/check_domain", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getCompanyById(id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.AUTH_SERVICE + "/company/" + id)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        editCompany(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + `/company/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        // // this.warning_msg = response.data.reason
                        // this.$refs.warning_modal.open();
                        // setTimeout(() => {
                        //     this.$refs.warning_modal.close();
                        // }, 3000);
                    }
                }
                catch(error){
                    reject(error.response.data.reason)
                    // this.warning_msg = error.response.data.reason
                    // this.$refs.warning_modal.open();
                    // setTimeout(() => {
                    //     this.$refs.warning_modal.close();
                    // }, 3000);
                }
            })
        },
        deleteCompany(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + `/company/delete`, {
                        data: params
                    })
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getDropdownsData(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/company/settings?type=${params.type}&skip=${params.skip}&limit=${params.limit}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },

        async getSocialDropdownsData(param) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/profile/settings?type=${param.type}&skip=${param.skip}&limit=${param.limit}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },

        // Products Access Related APIs
        getCompaniesSubscribedProducts(params) {
            return new Promise(async (resolve, reject) => {
                await axios.get(globals.AUTH_SERVICE + `/company/products/subscribed/get?company_id=${params.company_id}&search_key=${params.search_key}`).then(response => {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }).catch((err) => {
                    this.warning_msg = err.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                });     
            })
        },
        getAllProducts(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/company/products/get?company_id=${params.company_id}&search_key=${params.search_key}&skip=${params.skip}&limit=${params.limit}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // subscribeProducts(params) {
        //     return new Promise(async (resolve, reject) => {
        //         let response = await this.$http.post(globals.AUTH_SERVICE + `/company/products/add`, params)
        //         if (response.data.status_id == 1) {
        //             resolve(response.data)
        //         } else {
        //             reject(response.data.reason)
        //         }
        //     })
        // },
        // unSubscribeProducts(params) {
        //     return new Promise(async (resolve, reject) => {
        //         let response = await this.$http.post(globals.AUTH_SERVICE + `/company/products/remove`, params)
        //         if (response.data.status_id == 1) {
        //             resolve(response.data)
        //         } else {
        //             reject(response.data.reason)
        //         }
        //     })
        // },
        getEmployeesOfProduct(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/company/employees`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },

        // Getting User Company Domains API
        async checkDomainName(domain_name) {
            return new Promise(async (resolve, reject) => {
            let response = await this.$http.post("/check_domain_names_validity", { domain_names: domain_name })
            resolve(response.data)
            })
        },

        // Companies Branch API
        getListBranch(skip, limit, company_id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/company/branch?skip=${skip}&limit=${limit}&company_id=${company_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        createBranch(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/company/branch`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        updateBranchById(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + `/company/branch/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getBranchById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/company/branch/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        deleteBranchById(id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + `/company/branch/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        assignAsHeadQuarter(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/company/update/branch/headquarter?branch_id=${params.branch_id}&company_id=${params.company_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },

        // Companies Bank Details API
        getBankDetails(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/banks/list`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        addBankDetails(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/banks/`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getBankDetailsById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/banks/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        updateBankDetailsById(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + `/banks/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        deleteBankDetailsById(id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + `/banks/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },

        // Companies Outlets API
        getListOutlets(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/outlets/list`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // addOutlets(params) {
        //     return new Promise(async (resolve, reject) => {
        //         let response = await this.$http.post(globals.AUTH_SERVICE + `/outlets/`, params)
        //         if (response.data.status_id == 1) {
        //             resolve(response.data)
        //         } else {
        //             reject(response.data.reason)
        //         }
        //     })
        // },
        getOutletsById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/outlets/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        updateOutletsById(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + `/outlets/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        deleteOutletsById(id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + `/outlets/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },

        // Business Unit API
        createBusinessUnit(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/business_unit/`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getListBusinessUnit(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/business_unit/list`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getBusinessUnitById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/business_unit/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        updateBusinessUnitById(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + `/business_unit/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        deleteBusinessUnitById(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + `/business_unit/delete`, {
                        data: params
                    })
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getBusinessUnitDepartments(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/business_unit/departments?skip=${params.skip}&limit=${params.limit}&company_id=${params.company_id}&business_unit_id=${params.business_unit_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },

        // Department Unit API
        createDepartmentUnit(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/departments/`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        // this.warning_msg = response.data.reason
                        // this.$refs.warning_modal.open();
                        // setTimeout(() => {
                        //     this.$refs.warning_modal.close();
                        // }, 3000);
                    }
                }
                catch(error){
                    reject(error.response.data.reason)
                    // this.warning_msg = error.response.data.reason
                    // this.$refs.warning_modal.open();
                    // setTimeout(() => {
                    //     this.$refs.warning_modal.close();
                    // }, 3000);
                }
            })
        },
        getListDepartmentUnit(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/departments/list`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getDepartment(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/business_unit/departments?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&company_id=${params.company_id}&business_unit_id=${params.business_unit_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })

        },
        getDepartmentUnitById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/departments/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        updateDepartmentUnitById(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + `/departments/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        // this.warning_msg = response.data.reason
                        // this.$refs.warning_modal.open();
                        // setTimeout(() => {
                        //     this.$refs.warning_modal.close();
                        // }, 3000);
                    }
                }
                catch(error){
                    reject(error.response.data.reason)
                    // this.warning_msg = error.response.data.reason
                    // this.$refs.warning_modal.open();
                    // setTimeout(() => {
                    //     this.$refs.warning_modal.close();
                    // }, 3000);
                }
            })
        },
        deleteDepartmentUnitById(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + `/departments/delete`, {
                        data: params
                    })
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getDepartmentDropdown(company_id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/departments/dropdown?company_id=${company_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getDepartmentBusinessUnitDropdown(company_id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/departments/bu/dropdown?company_id=${company_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        getDepartmentHeadDropdown(company_id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/departments/head/dropdown?company_id=${company_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },

        // Employees API
        getListEmployees(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/employees/?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&company_id=${params.company_id}&show_suspended=true`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // createEmployees(params) {
        //     return new Promise(async (resolve, reject) => {
        //         let response = await this.$http.post(globals.AUTH_SERVICE + `/employees/`, params)
        //         if (response.data.status_id == 1) {
        //             resolve(response.data)
        //         } else {
        //             reject(response.data.reason)
        //         }
        //     })
        // },
        getEmployeesById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/employees/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // updateEmployeesById(params, id) {
        //     return new Promise(async (resolve, reject) => {
        //         let response = await this.$http.patch(globals.AUTH_SERVICE + `/employees/${id}`, params)
        //         if (response.data.status_id == 1) {
        //             resolve(response.data)
        //         } else {
        //             reject(response.data.reason)
        //         }
        //     })
        // },
        deleteEmployeeById(id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + `/employees/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        // this.warning_msg = response.data.reason
                        // this.$refs.warning_modal.open();
                        // setTimeout(() => {
                        //     this.$refs.warning_modal.close();
                        // }, 3000);
                    }
                }
                catch(error){
                    reject(error.response.data.reason)
                    // this.warning_msg = error.response.data.reason
                    // this.$refs.warning_modal.open();
                    // setTimeout(() => {
                    //     this.$refs.warning_modal.close();
                    // }, 3000);
                }
            })
        },

        /*API For Subsidiary Employee List */
        getSubsidiaryEmpList(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/employees/subsidiary?skip=${params.skip}&limit=${params.limit}&search_key=${params.search_key}&organization_id=${params.organization_id}&company_id=${params.company_id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        /*API For Create Link Employee */
        createLinkEmployee(params) {
             return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/employees/link/company`,params)
                    if (response.data.status_id == 1 ) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                 catch(error){
                    this.warning_msg = error.response.data.reason;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                 }
             })
        },
        /*API For Domain name in Employees */
        getDomainList(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.get(globals.AUTH_SERVICE + `/organization/domains?company_id=${params.company_id}`)
                    if (response.data.status_id == 1) {
                    resolve(response.data)
                    } else {
                    reject(response.reason)
                    this.warning_msg = response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                    }
                 }
                 catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                 }
             })
        },
        /*API For EMail Id Exists in Employees */
        checkEmailExist(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + `/registration/login_id/exists`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
             })
        },
        suspendEmployeeById(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/employees/status/update", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error)  {
                    reject(error.response.data.reason)
                }
            })
        },
        
    }
}
