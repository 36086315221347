// =========================================== Library Import ========================================
import Vue from "vue";

// =========================================== Component Import ========================================
import App from "./App";
import './registerServiceWorker';

// ============================================ JS Import ==============================================================
import router from "./router";
import store from './store/store';
import moment_timezone from "moment-timezone";
import VueOtpInput from "@bachdgvn/vue-otp-input";
import DatePicker from "vue2-datepicker";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import VeeValidate from "vee-validate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import vSelect from "vue-select";
import {HollowDotsSpinner} from "epic-spinners";
import VueTelInput from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";
import { LocationPicker } from "vue2-location-picker";
import * as VueGoogleMaps from "vue2-google-maps";
import axios from "axios";
import Vuex from "vuex";
var VueUploadComponent = require('vue-upload-component');
import ProgressBar from "vue-simple-progress";
import VueObserveVisibility from "vue-observe-visibility";
import VModal from "vue-js-modal";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import globals from './components/globals';
import Avatar from 'vue-avatar'
import vueDebounce from 'vue-debounce'

Vue.config.productionTip = false
Vue.prototype.moment_timezone = moment_timezone;
Vue.component("date-picker", DatePicker);
var VueCookie = require("vue-cookie");
Vue.use(vueDebounce);
Vue.use(VueCookie);
Vue.use(ElementUI);
Vue.use(VeeValidate);
Vue.use(VModal);
Vue.use(Avatar)
Vue.component("multiselect", Multiselect);
Vue.component("v-select", vSelect);
Vue.component("vue-tel-input", VueTelInput);
Vue.component("v-otp-input", VueOtpInput);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.component("progress-bar", ProgressBar);
Vue.component('file-upload', VueUploadComponent)
Vue.component("hollow-dots-spinner", HollowDotsSpinner);
import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VuePhoneNumberInput);
Vue.use(VueObserveVisibility);
Vue.use(VueTelInput);
Vue.use(Vuex);
Vue.use(VueTextareaAutosize);
Vue.use(SweetModal);
Vue.use(SweetModalTab);
Vue.use(VueSweetalert2);

Vue.component('file-upload', VueUploadComponent)
Vue.config.productionTip = false;
locale.use(lang);
Vue.prototype.$serverUrl = globals.CRM_URL;
var token = Vue.cookie.get("Token");
Vue.prototype.$http = axios.create({
  baseURL: Vue.prototype.$serverUrl,
  headers: {
    Authorization: token,
    "Content-Type": "application/json",
    // withCredentials: true
  },
});

Vue.prototype.$http.interceptors.response.use(undefined, err => {
  console.log(err.response.status);
  if(err.response.status == '403') {
      var res = document.cookie;
      var multiple = res.split(";");
      for(var i = 0; i < multiple.length; i++) {
          var key = multiple[i].split("=");
          document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
      }
      setTimeout(() => {
          window.location.href = globals.ADMIN_URL + '/login';
      }, 500);
  } else {
      // next();
      return Promise.reject(err);
  }
})
Vue.use(LocationPicker, {
  installComponents: false, // If true, create it globally
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

new Vue({
  router,
  store,
  components: {
    SweetModal,
    SweetModalTab,
    LocationPicker
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  render: h => h(App)
}).$mount('#app')
