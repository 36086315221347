import global from "../globals.js"
export default {
    data() {
        return {
        }
    },
    methods:{
        // Profile Settings
        async getDropdownsData(param) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + `/profile/settings?type=${param.type}&skip=${param.skip}&limit=${param.limit}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async checkLogin(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.AUTH_SERVICE + "/auth/", params);
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                } 
            })
        },
        // Basic Info APIS
        async getPersonalInfo(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + "/profile/basic/" + id)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async updatePersonalInfo(params, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.patch(global.AUTH_SERVICE + `/profile/basic/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async deleteSignatureById(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.AUTH_SERVICE + `/profile/signature/delete`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // Address APIS
        async getAddressApi(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + "/profile/address/" + id)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async addNewAddress(params, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.put(global.AUTH_SERVICE + `/profile/address/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async updateAddress(params, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.patch(global.AUTH_SERVICE + `/profile/address/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async deleteAddressById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.delete(global.AUTH_SERVICE + `/profile/address/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // Birthday APIS
        async getBirthdayList(skip) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + `/profile/birthday?skip=${skip}&limit=${10}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async createBirthday(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.AUTH_SERVICE + `/profile/birthday`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async getBirthdayById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + `/profile/birthday/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async updateBirthday(params, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.put(global.AUTH_SERVICE + `/profile/birthday/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async deleteBirthday(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.delete(global.AUTH_SERVICE + `/profile/birthday/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async deleteBirthdayMultiple(param) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.AUTH_SERVICE + `/profile/birthday/delete/multiple`, param)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // Events APIS
        async getEventsList(param) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + `/profile/events?skip=${param.skip}&limit=${param.limit}&start_date=${param.start_date}&end_date=${param.end_date}&event_between=${param.event_between}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async createEvents(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.AUTH_SERVICE + `/profile/events`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async getEventsById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + `/profile/events/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async updateEvents(params, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.put(global.AUTH_SERVICE + `/profile/events/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async deleteEvents(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.delete(global.AUTH_SERVICE + `/profile/events/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },

        // Communication View
        async getCommunication(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + `/profile/communication/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async addUpdateCommunication(params, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.put(global.AUTH_SERVICE + `/profile/communication/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async deleteCommunicationPhoneById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.delete(global.AUTH_SERVICE + `/profile/communication/phone/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async deleteCommunicationSocialMediaById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.delete(global.AUTH_SERVICE + `/profile/communication/social_media/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async deleteCommunicationEmergencyContactById(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(global.AUTH_SERVICE + `/profile/emergency_contact`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // Preferences View 
        async getPreferences(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + `/profile/preferences/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async updatePreferences(params, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.patch(global.AUTH_SERVICE + `/profile/preferences/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        // Health View 
        async getHealthInfo(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(global.AUTH_SERVICE + `/profile/health/${id}`)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        async updateHealthInfo(params, id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.patch(global.AUTH_SERVICE + `/profile/health/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
    }
}