<template>
    <div class="app-digi">
        <div class="horizontal-layout-1" v-if="loadingGif">
            <div class="d-flex justify-content-center align-items-center w-100 h-100">
                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
            </div>
        </div>
        <div class="w-100" v-else>
            <div class="horizontal-layout-1 h-100" v-if="!showaccess">
                <div id="main" v-if="isChrome && loggedIn && otpVerified && !register && !user_verify">
                    <div class="main-navbar">
                        <navbar></navbar>
                    </div>
                    <div class="digicontainer">
                        <div class="dccontainer">
                            <div class="ps-container ps w-100">
                                <div class="bigcontainer" ref="containerDiv">
                                    <div class="content-block h-100">
                                        <div>
                                        <Menubar />
                                        </div>
                                        
                                        <div class="p-0 position-relative" style="z-index: 222;">
                                            <foot></foot>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="horizontal-layout-1" v-else-if="user_verify && register || !otpVerified">
                    <div id="main">
                        <router-view/>
                    </div> 
                </div>
            </div>
            <div class="horizontal-layout-1" v-if="showaccess">
                <div id="main">
                    <div class="main-navbar">
                        <nav-top></nav-top>
                    </div>
                    <div class="digicontainer">
                        <div class="dccontainer" style="height: 90vh;">
                            <div class="ps-container ps w-100">
                                <div class="bigcontainer" ref="containerDiv">
                                    <div class="content-block h-100">
                                        <main class="w-100 h-100 my-5">
                                            <div class="w-100 h-100">
                                                <access-denied></access-denied>
                                            </div>
                                        </main>
                                        <div class="p-0 position-fixed w-100" style="z-index: 222; bottom: 0;">
                                            <footer-bottom></footer-bottom>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <switch-accessable-company modal_name="SwitchAccessableCompany" :error_msg="error_msg" v-if="switch_accessable" @DeleteSessionToken="DeleteSessionToken"></switch-accessable-company>
        <accept-two-step-verification v-if="accept_two_step_verification" @hideTwoStepVerification="hideTwoStepVerification" modal_name="two-step-verification"></accept-two-step-verification>
    </div>
</template>
<script>
const AcceptTwoStepVerification = () => import('./components/Profile/AcceptTwoStepVerification');
import Menubar from './components/Menubar.vue';
import auth from './components/mixins/auth.js';
const Navbar = () => import('./components/Navbar');
const Foot = () => import('./components/Footer');
const NavTop = () => import('./components/NavTop');
const FooterBottom = () => import('./components/FooterBottom');
const AccessDenied = () => import('./components/AccessDenied');
import { EventBus } from "./components/eventBus/nav-bar-event.js";
import Loader from "./components/Loader";
import { HalfCircleSpinner } from "epic-spinners";
import globals from './components/globals';
import axios from 'axios';
const SwitchAccessableCompany = () => import('./components/alertModals/SwitchAccessableCompany')
export default {
    name: 'App',
    data(){
        return{
            loadingGif: true,
            isChrome: "chrome",
            isExplorer: "explorer",
            loggedIn: false,
            otpVerified: false,
            registered: true,
            register: false,
            is_api_called: false,
            showaccess: false,
            user_verify: false,
            accept_two_step_verification: false,
            login_id_user: "",
            switch_accessable: false,
            error_msg: ''
            // is_from_login: false
        }
    },
    mixins: [auth],
    components:{
        Navbar,
        Foot,
        Menubar,
        Loader,
        HalfCircleSpinner,
        FooterBottom,
        NavTop,
        AccessDenied,
        AcceptTwoStepVerification,
        SwitchAccessableCompany
    },
    created() {
        // let session_id = sessionStorage.getItem('session_id');
        // if (this.$cookie.get("Token") && session_id) {
        // if (this.$cookie.get("Token")) {
        //     if (this.$route.path.startsWith('/user/verify')) {
        //         this.user_verify = true;
        //         this.loadingGif = false;
        //         this.$router.push(`/user/verify?user_id=${this.$route.query.user_id}&secret=${this.$route.query.secret}&link_for=${this.$route.query.link_for}`);
        //     }
        //     else { 
        //         this.user_verify = false;
        //         if (this.$route.path == "/register" || this.$route.path == "/login") {
        //             this.$router.push("/");
        //         }
        //         this.getLoggedInUser();
        //     }
        // } else {
        //     this.loadingGif = false;
        //     this.register = true;
        //     if (this.$route.path.startsWith('/user/verify')) {
        //         this.user_verify = true;
        //         this.$router.push(`/user/verify?user_id=${this.$route.query.user_id}&secret=${this.$route.query.secret}&link_for=${this.$route.query.link_for}`);
        //     }
        //     else if (this.$route.query.redirect_to) {
        //         this.user_verify = false;
        //         this.$router.push("/login?redirect_to=" + this.$route.query.redirect_to);
        //     } 
        //     else {
        //         this.user_verify = false;
        //         this.$router.push("/login");
        //     }
        // }
    },
    methods: {
        hideTwoStepVerification() {
            this.accept_two_step_verification = false;
        },
        async checkTwoFactorVerification() {
            try {
                let params = {
                login_id: this.loggedInUser.login_id
                }
                let response = await this.checkLogin(params);
                if(response.status_id == 1 && response.is_two_step_verification == false) {
                this.accept_two_step_verification = true;
                setTimeout(() => {
                    this.$modal.show("two-step-verification");
                }, 500);
                }
            }
            catch(err) {
                // console.log(err);
            }
        },
        registerInterceptors() {
            this.$http.interceptors.request.use(
                config => {
                    if (this.$cookie.get('Token')) {
                        config.headers.Authorization = this.$cookie.get(
                            'Token'
                        )
                    }
                    return config
                },
                error => {
                    return Promise.reject(error)
                }
            )
            this.$http.interceptors.response.use(undefined, err => {
                if(err.response.status == '403') {
                    this.switch_accessable = true
                    this.error_msg = err.response.data.reason || ''
                    setTimeout(() => {
                        this.$modal.show('SwitchAccessableCompany')
                    }, 500);
                } else {
                    return Promise.reject(err)
                }
            })
        },
        DeleteSessionToken(){
            this.$cookie.delete("Token", {
                domain: 'digicollect.com'
            });
            var res = document.cookie;
            var multiple = res.split(";");
            for(var i = 0; i < multiple.length; i++) {
                var key = multiple[i].split("=");
                document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
            }
            setTimeout(() => {
                window.location.href = globals.ADMIN_URL + '/login';
            })
            // this.$cookie.delete("Token");
            // this.$cookie.delete('Token', {
            //     domain: 'digicollect.com'
            // });
            // this.$cookie.delete('Token', {
            //     domain: '.digicollect.com'
            // });
            // this.$cookie.delete('Token', {
            //     domain: '.digicollect.com',
            //     path: '/'
            // });
            // this.$cookie.delete('Token', {
            //     domain:document.location.hostname,
            //     path:document.location.pathname
            // });
            // this.$cookie.delete('Token', {
            //     domain:document.location.hostname,
            // });
            // this.$cookie.delete('Token', {
            //     path:document.location.pathname
            // });
            // this.$cookie.delete('Token', {
            //     path: '/'
            // });
            // console.log('deleting Token')
            // var res = document.cookie;
            // var multiple = res.split(";");
            // for(var i = 0; i < multiple.length; i++) {
            //     var key = multiple[i].split("=");
            //     document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
            // }
            // setTimeout(() => {
            //     window.location.href = global.ADMIN_URL + '/login';
            // }, 500);
        },
        // Geting Logged In User and Set Token
        async getLoggedInUser() {
            await this.$http.get(globals.AUTH_SERVICE + "/auth/get_loggedin_user").then(response => {
                this.loadingGif = false;
                this.user_verify = false;
                if (response.data.status_id == 1) {
                    this.loggedIn = true;
                    axios.defaults.headers.common.Authorization = this.$cookie.get("Token");
                    this.$store.commit("setLoggedInUser", response.data.response);
                    if (response.data.response.is_verified) {
                        this.otpVerified = true;
                        this.registerInterceptors();
                        // if(this.is_from_login){
                        //     this.checkTwoFactorVerification()
                        // }
                    } else {
                        this.$router.push("/verifyOtp");
                    }
                } else {
                    this.$store.commit("setLoggedInUser", null);
                    this.loggedIn = false;
                    let routePath = this.$route.path.split("/")[1];
                    switch (routePath) {
                        case "register":
                        case "registerInvited":
                            break;
                        default:
                            window.location.href = globals.ADMIN_URL;
                    }
                }
            }).catch(error => {
                this.loadingGif = false;
                this.user_verify = false;
                this.$cookie.delete("Token", {
                    domain: 'digicollect.com'
                });
                var res = document.cookie;
                var multiple = res.split(";");
                for(var i = 0; i < multiple.length; i++) {
                    var key = multiple[i].split("=");
                    document.cookie = key[0]+" =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
                }
                window.location.href = globals.ADMIN_URL + '/login';
                // this.showaccess = true
                // this.$router.push({
                //     name: "AccessDenied"
                // })
            });
        },
    },
    // beforeRouteEnter(to, from, next) {
    //     console.log("hi12")
    //     console.log(from)
    //     if(from == "/login") this.is_from_login = true;
    //     else this.is_from_login = false;
    //         next()
    // },
    mounted() {
        // jQuery('.bigcontainer').animate({scrollTop:0},0);
        setTimeout(() => {
            if (this.$cookie.get("Token")) {
                if (this.$route.path.startsWith('/user/verify')) {
                    this.user_verify = true;
                    this.loadingGif = false;
                    this.$router.push(`/user/verify?user_id=${this.$route.query.user_id}&secret=${this.$route.query.secret}&link_for=${this.$route.query.link_for}`);
                }
                else { 
                    this.user_verify = false;
                    if (this.$route.path == "/register" || this.$route.path == "/login") {
                        this.$router.push("/");
                    }
                    this.getLoggedInUser();
                }
            } else {
                this.loadingGif = false;
                this.register = true;
                if (this.$route.path.startsWith('/user/verify')) {
                    this.user_verify = true;
                    this.$router.push(`/user/verify?user_id=${this.$route.query.user_id}&secret=${this.$route.query.secret}&link_for=${this.$route.query.link_for}`);
                }
                else if (this.$route.query.redirect_to) {
                    this.user_verify = false;
                    this.$router.push("/login?redirect_to=" + this.$route.query.redirect_to);
                } 
                // else {
                //     this.user_verify = false;
                //     this.$router.push("/login");
                // }
            }
        }, 1000);
        navigator.detectBrowser = (function () {
            var ua = navigator.userAgent,
                tem,
                M =
                ua.match(
                    /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
                ) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return "IE " + (tem[1] || "");
            }
            if (M[1] === "Chrome") {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null)
                    return tem
                        .slice(1)
                        .join(" ")
                        .replace("OPR", "Opera");
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
            return M.join(" ");
        })();
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        var str = navigator.detectBrowser;
        var windowNav = window.navigator;
        var isIOSChrome = windowNav.userAgent.match("CriOS");
        var browser = str.substring(0, str.indexOf(" "));
        var version = str.substring(str.indexOf(" "));
        version = version.trim();
        version = parseInt(version);
        if (
            (browser == "Chrome") ||
            (browser == "Firefox") ||
            (browser == "Safari") ||
            (browser == "Opera") ||
            (browser == "Edge")
        ) {
            this.isChrome = true;
            this.isExplorer = false;
        } else if (browser == "IE") {
            this.isExplorer = true;
            this.isChrome = false;
        } else if (isIOSChrome) {
            this.isChrome = true;
            this.isExplorer = false;
        } else if (iOS == "iPhone") {
            this.isChrome = true;
            this.isExplorer = false;
        }
        EventBus.$on("scrollToTop", () => {
            this.$nextTick(function () {
                this.$refs.containerDiv.scrollTop = 0;
            });
        });
        window.onpopstate = function (event) {
            let to = window.location.href.split("/");
            if (to[3] === "callreport") {
                window.history.back();
            }
        };
        EventBus.$on("personal_info_data_update", () => {
            setTimeout(() => {
                this.getLoggedInUser();
            }, 500);
        })
    },
    destroyed() {
        EventBus.$off("scrollToTop");
        EventBus.$off("personal_info_data_update");
    },
    watch: {
        "$route.path": function (path) {
            if (this.$cookie.get("Token") && path == '/register') {
                this.otpVerified ? this.$router.push("/") : this.$router.push("/verifyOtp");
            }
            if (this.$cookie.get("Token") && path == '/verifyOtp') {
                this.otpVerified ? this.$router.push("/") : this.$router.push("/verifyOtp");
            }
            if (this.$cookie.get("Token") && path == "/login") {
                this.$router.push("/");
            }
        },
        $route(to, from) {
            if (to.path != "/register" && from.path == "/login" && !this.$cookie.get("Token") && this.loadingGif) {
                this.$router.push("/login");
            }
        },
        'activeRefresh': function (val) {
            if(val) {
                this.getLoggedInUser();
            }
        },
    },
    computed: {
        loggedInUser(){
            return this.$store.state.loggedInUser
        },
        userAccounts() {
            return this.$store.state.userAccounts;
        },
        account() {
            return this.$store.state.account;
        },
        activeRefresh() {
            return this.$store.state.activeRefresh;
        }
    },
}
</script>
<style scoped>
    .ps {
  position: relative;
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
.ps {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
.ps-container {
  position: relative;
}
.access-denied-alert {
    position: relative !important;
    top: 30% !important;
    justify-content: center !important;
    display: flex !important;
    font-size: 18px;
    text-align: center;
    color: #0d84f2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 220px;
    position: relative;
    background: #ffffff;
    -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3, MakeShadow=true, ShadowOpacity=0.30);
    -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)";
    zoom: 1;
    border-radius: 18px;
    width: 50%;
    margin: 0 auto;
}

.access-denied-alert span {
    justify-content: center !important;
    display: block !important;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}
.access-denied-alert span img {
    width: 40px;
}
.overflow-hidden{
    overflow: hidden !important;
}
.main-menu{
    padding-left: 11.5rem;
}
@media only screen and (max-width: 767px){
    .main-menu{
        padding-left: 1.5rem !important;
    }
}
</style>
